import AbstractView from "./AbstractView"
import DateYourCat from '../../assets/date_your_cat_logo.svg'
import { go } from "../utils/navigate";
import { AppSession, UserSession } from "../utils/store";
import config from '../../../config.json';

export default class Home extends AbstractView {

  handleClick = () => {
    UserSession.hasClikedIn = true;
    AppSession.audioPlayer.play();
    go('appearance');
  }

  render() {
    if (gtag) gtag('event', 'site-mode', config.mode);
    return <section className={`home`}>
      <div className="home__header" onClick={this.handleClick}>
        <DateYourCat />
      </div>
      {config.mode === 'COMING_SOON' && <span className="home__message">coming soon! your AI cat will go live<br/>in the next few days.</span>}
      { config.mode === 'OUT' && <span className="home__message">damn! we’ve hit our user limit.<br /><a href={config.fundraiserURL} target="_blank">click here</a> to keep dateyourcat.AI alive.</span>}
      { config.mode === 'APP' && <button onClick={this.handleClick} className="button">meow</button> }
      <span className="home__legal">Powered by <a href="https://openai.com/blog/chatgpt/" target='_blank'>CatGPT</a> & <a href="https://pda.nyc/" target='_blank'>PDA</a>.</span>
    </section>
  }
}