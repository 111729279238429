import react, { Component, useState, useEffect, useRef } from 'react';
import * as ReactDOM from 'react-dom/client';
import About from './views/About';
import Appearance from './views/Appearence';
import Chat from './views/Chat';
import Details from './views/Details';
import Formality from './views/Formality';
import Home from './views/Home';
import Personality from './views/Personality';
import { AppSession, UserSession } from './utils/store';
import config from '../../config.json';
import { shuffle } from './utils/array';
import AudioPlayer, { DEFAULT_VOLUME } from './components/AudioPlayer';
import UnityApp from './components/UnityApp';
import { go } from './utils/navigate';

import Heart from '../assets/heart.svg'
import { EVENT_TYPES, trackEvent } from './utils/analytics';

gsap.registerPlugin(SplitText);

const views = {
  '': <Home />,
  'about': <About />,
  'appearance': <Appearance />,
  'personality': <Personality />,
  'details': <Details />,
  'formality': <Formality />,
  'chat': <Chat />
}

const App = props => {

  const [init, setInit] = useState(false);
  const [view, setView] = useState(views['']);
  const [isAboutOppen, setIsAboutOpen] = useState(false);
  const [route, setRoute] = useState('');
  const [audioIsPlaying, setAudioIsPlaying] = useState(false);
  const [trackIndex, setTrackIndex] = useState(0);
  const videoRef = useRef(undefined)
  const tracks = shuffle(config.audio.lobby.concat());
  tracks.unshift(config.audio.chat);


  const handleRouteChange = ({ detail }) => {
    const { route } = detail;
    if (config.mode !== 'APP') {
      if (route !== '' && route !== 'about') return;
    }
    if (views[route]) {
      trackEvent(EVENT_TYPES.SCREEN_VIEW, {
        page_title: route,
      })
      setView(views[route]);
      setRoute(route);
    }
  }

  const handleAboutChange = ({detail}) => {
      setIsAboutOpen(detail.open);
      trackEvent(EVENT_TYPES.MODAL, {
        modal: 'about',
        action: detail.open ? 'open' : 'close'
      });
  }

  const handlePlayPause = () => {
    if (AppSession.audioPlayer && AppSession.audioPlayer.paused) {
      setAudioIsPlaying(true);
      AppSession.audioPlayer.play();
      trackEvent(EVENT_TYPES.AUDIO, {
        action: 'play-click'
      });
    } else if (AppSession.audioPlayer && !AppSession.audioPlayer.paused) {
      setAudioIsPlaying(false);
      AppSession.audioPlayer.pause();
      trackEvent(EVENT_TYPES.AUDIO, {
        action: 'pause-click'
      });
    }
  }

  useEffect(() => {
    if (init) return;
    window.addEventListener('routechange', handleRouteChange);
    window.addEventListener('aboutchange', handleAboutChange);
    setInit(true);
  }, [init]);

  useEffect(() => {
    if (AppSession.audioPlayer) {
      AppSession.audioPlayer.addEventListener('play', () => {
        trackEvent(EVENT_TYPES.AUDIO, {
          action: 'started'
        });
        setAudioIsPlaying(true);
      });
    }
  }, [AppSession.audioPlayer]);

  return <div>
    {config.mode === "APP" && <UnityApp />}
    { config.mode === "COMING_SOON" && <div className="catgpt__video-wrapper">
      <video ref={videoRef} src="video/table-optimized.mp4" autoPlay={true} loop={true} muted={true} className="catgpt__video-bg"></video>
    </div> }

    {route !== '' && route !== 'about' && <svg onClick={handlePlayPause} className={`audio ${audioIsPlaying && 'audio--playing'}`} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 20 21" xmlSpace="preserve">
      <path id="line_1" d="M2,21c-1.1,0-2-0.9-2-2V2c0-1.1,0.9-2,2-2s2,0.9,2,2v17C4,20.1,3.1,21,2,21z" />
      <path id="line_2" d="M10,21c-1.1,0-2-0.9-2-2V8c0-1.1,0.9-2,2-2s2,0.9,2,2v11C12,20.1,11.1,21,10,21z" />
      <path id="line_3" d="M18,21c-1.1,0-2-0.9-2-2V2c0-1.1,0.9-2,2-2s2,0.9,2,2v17C20,20.1,19.1,21,18,21z" />
    </svg>}
    <div className='catgpt__wrapper'>
      {view}
      {route !== 'about' && <a href="#" onClick={e => {
        e.preventDefault();
        setIsAboutOpen(true);
      }} className="about__cta">about</a>}

      <AudioPlayer tracks={tracks} />
      <About open={isAboutOppen} />
    </div>
  </div >
}

const root = ReactDOM.createRoot(document.querySelector('#catgpt-app'))
root.render(<App />);
