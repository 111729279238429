import React from "react";
import { EVENT_TYPES, trackEvent } from "../utils/analytics";
import { typeIn } from "../utils/animation";
import { go } from "../utils/navigate";
import { UserSession } from "../utils/store";
import AbstractView from "./AbstractView"

const PLACEHOLDERS = {
  food: 'what’s your cat’s favorite food?',
  toy: 'what’s your cat’s favorite toy?',
  activity: 'what does your cat love to do?',
  // hiss: 'what makes your cat hiss?',
  pet: 'where does your cat like to be pet?'
}

export default class Details extends AbstractView {
  state = {
    food: '',
    toy: '',
    activity: '',
    pet: '',
    focuesInput: undefined
  }

  constructor(props) {
    super(props);
    this.section = React.createRef(undefined);
  }

  isValid() {
    const answered = Object
      .keys(PLACEHOLDERS)
      .map(key => (this.state[key]))
      .filter(value => value);
    return answered.length === Object
      .keys(PLACEHOLDERS).length;
  }

  componentDidMount() {
    this.setState(UserSession.details);
    const h3 = this.section.current.querySelector('.details__text h3');
    typeIn(h3);
  }

  handleChange = (e, key) => {

    this.setState({ [key]: e.target.value });
  }

  handleBlur = key => {
    UserSession.details[key] = this.state[key];
    this.setState({ focuesInput: '' });
  }

  handlefocus = key => {
    this.setState({ focuesInput: key });
  }

  render() {
    return <section className="details" ref={this.section}>
      <h5>tell us about your date</h5>
      <div className="details__wrapper">
        <div className="details__text">
          <h3>purrfect!<br />
            just need<br />
            a few more<br />
            deets</h3>
        </div>
        <div className="details__form">
          <ul className="details__form-list">
            {Object.keys(PLACEHOLDERS).map(key => (
              <li className="details__form-list-item" key={key}>
                <label className='details__label' htmlFor={key}>{PLACEHOLDERS[key]}</label>
                <input
                  id={key}
                  type='text'
                  className="details__input input"
                  onChange={e => { this.handleChange(e, key) }}
                  onFocus={e => { this.handlefocus(key) }}
                  onBlur={e => { this.handleBlur(key) }}
                  value={this.state[key]}
                />
              </li>
            ))}
          </ul>
          <div className="controls">
            <button
              onClick={e => {
                const {food, toy, activity, pet} = this.state;
                trackEvent(EVENT_TYPES.CONFIG, {
                  type: 'details',
                  food, toy, activity, pet
                });
                go('formality')
              }}
              className={`button ${!this.isValid() && 'button--disabled'}`}>next</button>
          </div>
        </div>
      </div>
    </section>
  }
}