import { randomNumber } from "./number";

export const typeIn = el => {
  const split = new SplitText(el, { type: 'words,chars,lines' });
  gsap.set(split.chars, {visibility: 'hidden'});
  const tl = gsap.timeline();
  split.chars.forEach((char, index) => {
    const duration = Math.round(randomNumber(0.05, 0.1) * 100)/100;
    tl.to(char, {opacity:1, onStart:() => {
      char.style.visibility = 'visible';
    }, duration})
  });
}