import { UserSession } from "./store";

import affectionate from '../../../davinci/affectionate.json';
import mischievous from '../../../davinci/mischievous.json';
import highMaintenance from '../../../davinci/high-maintenance.json';
import skittish from '../../../davinci/skittish.json';

export const dialogs = {
  affectionate,
  mischievous,
  skittish,
  'high maintenance': highMaintenance
}

const apiCall = data => (
  fetch("https://api.openai.com/v1/completions", {
    body: JSON.stringify(data),
    headers: {
      Authorization: "Bearer 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",
      "Content-Type": "application/json"
    },
    method: "POST"
  }).then((response) => response.json())
)

export const chat = {
  connect: () => {

    if (UserSession.personality === '') {
      UserSession.personality = 'affectionate';
    }

    let prompt = dialogs[UserSession.personality]
      .replaceAll('[name]', UserSession.formality.name)
      .replaceAll('[catName]', UserSession.formality.catName)
      .replaceAll('[food]', UserSession.details.food)
      .replaceAll('[toy]', UserSession.details.toy)
      .replaceAll('[activity]', UserSession.details.activity)
      .replaceAll('[hiss]', UserSession.details.hiss)
      .replaceAll('[pet]', UserSession.details.pet);

    UserSession.chat.messages = prompt;

    const data = {
      "model": "text-davinci-003",
      "prompt": prompt,
      "temperature": 0.9,
      "max_tokens": 256,
      "top_p": 1,
      "frequency_penalty": 0.15,
      "presence_penalty": 0.15,
      "stop": ["USER:", "\nUSER:"]
    };

    return apiCall(data);
  },

  send: (prompt) => {

    UserSession.chat.messages += "\nUSER: " + prompt;

    const data = {
      "model": "text-davinci-003",
      "prompt": UserSession.chat.messages,
      "temperature": 0.9,
      "max_tokens": 256,
      "top_p": 1,
      "frequency_penalty": 0.15,
      "presence_penalty": 0.15,
      "stop": ["USER:", "\nUSER:"]
    };
    return apiCall(data);
  }
}