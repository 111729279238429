import Lottie from "lottie-web";
import { useEffect, useRef } from "react"

export const Loader = props => {
  const lottieEl = useRef(undefined);
  useEffect(() => {
    Lottie.loadAnimation({
      container: lottieEl.current, // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '/lottie/134296-pet-loading.json' // the path to the animation json
    });
  }, [lottieEl])
  return <div className={`loader${ props.loaded ? ' loader--loaded' : '' }`}>
    <div ref={lottieEl} className="loader__lottie"></div>
    <span className="loader__progress">{props.children}</span>
  </div>
}