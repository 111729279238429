import AbstractView from "./AbstractView"
import Heart from "../../assets/heart.svg"
import Close from "../../assets/close.svg"
import config from '../../../config.json';
import React from "react";

export default class About extends AbstractView {

  constructor(props){
    super(props);
    this.sectionRef_ = React.createRef();
  }

  render() {
    return <section ref={this.sectionRef_} className={`about${ this.props.open ? ` about--open` : ''}`}>
      <a href="#" className="about__close" onClick={e => {
        e.preventDefault();
        if (gtag) gtag('event', 'about-close');
        const event = new CustomEvent('aboutchange', {detail: {open: this.props.open !== true}});
        window.dispatchEvent(event);
      }}>
        <Close />
      </a>
      <div className="about__text">
        {config.about.split('\n').map((str, index) => (<p key={index} dangerouslySetInnerHTML={{ __html: str}}/>))}
      </div>
      <div className="about__pda-logo">
        <Heart />
      </div>
    </section>
  }
}