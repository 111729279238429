export const EVENT_TYPES = {
  AUDIO: 'audio',
  CAT: 'cat',
  CHAT: 'chat',
  CONFIG: 'config',
  ERROR: 'error',
  MODAL: 'modal',
  SCREEN_VIEW: 'screen_view',
}

export const trackEvent = (type, params) => {
  if (!window.gtag) return;
  if (!params) window.gtag('event', type);
  if (params) window.gtag('event', type, params);
}